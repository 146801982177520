<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">Blend Sheet</h5>
                    <p class="text-sm text-body mb-0"></p>
                  </div>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-data-table :headers="headers" :items="blendPlans" class="table element" :page.sync="page"
                  hide-default-footer @page-count="pageCount = $event" :items-per-page="itemsPerPage"
                  mobile-breakpoint="0">
                  <template v-slot:[`item.productionDate`]="{ item }">
                    <span>
                      {{ formatDate(item.productionDate) }}
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip label small :ripple="false" :color="planStatus(item).color">
                      {{ planStatus(item).label }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-row>
                      <v-col>
                        <v-btn :ripple="false" icon text width="auto"
                          class="pe-4 ps-0 mb-0 text-default text-capitalize icon-move-right align-center"
                          @click="onNavigate(item)">
                          View
                          <v-icon right dark size="12" class="ms-0 mt-1"> fa fa-arrow-right </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="card-padding">
                <v-row>
                  <v-col cols="6" lg="3" class="d-flex align-center">
                    <span class="text-body me-3 text-sm">Items per page:</span>
                    <v-text-field hide-details type="number" outlined min="-1" max="15"
                      background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light :value="itemsPerPage"
                      @input="itemsPerPage = parseInt($event, 10)" placeholder="Items per page"
                      class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="ml-auto d-flex justify-end">
                    <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination"
                      color="#38bd34" v-model="page" :length="pageCount" circle></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../api";
export default {
  name: "BlendSheets",
  data() {
    return {
      overlay: false,
      blendPlans: [],
      dialog: false,
      headers: [
        { text: "Name", width: "150", value: "name" },
        { text: "Blend", width: "150", value: "blendCodeName" },
        { text: "Blend Type", width: "150", value: "blendTypeName" },
        { text: "Quantity", width: "120", value: "productionQuantity" },
        { text: "Source No", width: "100", value: "sourceNumber" },
        { text: "Production Date", width: "120", value: "productionDate" },
        { text: "Warehouse", width: "140", value: "companyWarehouseName" },
        // { text: "Generated", width: "100", value: "planGenerationStatus" },
        { text: "Status", width: "50", value: "status" },
        { text: "Actions", width: "50", value: "actions" },
      ],
      editedItem: {
        productionDate: "",
        productionQuantity: 0,
        blendCodeId: 0,
        companyWarehouseId: 0,
      },
      defaultItem: {
        productionDate: "",
        productionQuantity: 0,
        blendCodeId: 0,
        companyWarehouseId: 0,
      },

      // Pagination
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.editedItem = Object.assign(this.defaultItem, {});
      }
    },
  },
  computed: {
    ...mapGetters({
      currentPage: "getCurrentPage",
    }),
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    planStatus(item) {
      if (!item.approvalStatus && !item.cancellationStatus) {
        return { label: "Pending", color: "rgba(255, 110, 64, 0.5)" };
      }
      if (item.approvalStatus) return { label: "Approved", color: "rgba(105, 240, 174, 0.5)" };
      if (item.cancellationStatus) return { label: "Cancelled", color: "rgba(237, 63, 59, 0.5)" };
    },
    onNavigate(item) {
      this.$router.push({
        name: "Blend Sheet Detail",
        params: { blendPlan: item, currentPage: this.currentPage },
      });
    },
    onSave(data) {
      this.blendPlans.push(data);
      this.dialog = false;
    },
    onEdit(item) {
      const _item = { ...item };
      this.editedItem.productionDate = _item.productionDate;
      this.editedItem.productionQuantity = _item.productionQuantity;
      this.editedItem.blendCodeId = _item.blendCodeId;
      this.editedItem.companyWarehouseId = _item.companyWarehouseId;
      this.dialog = true;
    },
  },
  async created() {
    this.blendPlans = await api.getAllApprovedPlans();
    this.blendPlans.sort((a, b) => {
      if (a.name < b.name) {
        return 1;
      }
      if (a.name > b.name) {
        return -1;
      }
      return 0;
    });
  },
};
</script>

<style scoped>
.element .v-data-table__wrapper::-webkit-scrollbar {
  width: 24px;
  height: 8px;
  background-color: #143861;
}
</style>
